import { render, staticRenderFns } from "./WeChatCustomerService.vue?vue&type=template&id=583a0b05&scoped=true"
import script from "./WeChatCustomerService.vue?vue&type=script&lang=js"
export * from "./WeChatCustomerService.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583a0b05",
  null
  
)

export default component.exports